import { useEffect, useState } from 'react';

export const useActiveElement = (): {
  activeElement: string;
  listenersReady: boolean;
} => {
  const [listenersReady, setListenersReady] = useState<boolean>(false);
  const [activeElement, setActiveElement] = useState<string>('');

  useEffect(() => {
    const onEvent = e => {
      if (e.target.name) setActiveElement(e.target.name.toString());
    };

    window.addEventListener('focus', onEvent, true);
    window.addEventListener('blur', onEvent, true);

    setListenersReady(true);

    return () => {
      window.removeEventListener('focus', onEvent);
      window.removeEventListener('blur', onEvent);
    };
  }, []);

  return {
    activeElement,
    listenersReady,
  };
};
