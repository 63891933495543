import Cookie from 'js-cookie';
import { get, getLocalStorage } from '.';

const FBC_COOKIE_KEY = '_fbc';

export function getFbc(localStorageKey: string): string | null {
  return Cookie.get(FBC_COOKIE_KEY)
    ? Cookie.get(FBC_COOKIE_KEY)
    : getFbclidFromLocalStorage(localStorageKey);
}

function getFbclidFromLocalStorage(localStorageKey: string): string | null {
  const data = getLocalStorage(localStorageKey);
  const fbclid = get(data, 'parameters.fbclid', null);
  const fbcTime = get(data, 'parameters.fbcTime', new Date().getTime());

  if (!fbclid) {
    return null;
  }

  return `fb.1.${fbcTime}.${fbclid}`;
}

export default getFbc;
