import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isLeapYear from 'dayjs/plugin/isLeapYear';

dayjs.extend(advancedFormat);
dayjs.extend(isLeapYear);

export const formatDate = (date: string | Date, format: string): string => {
  return dayjs(date).format(format);
};

export const leapYear = (date: string): boolean => {
  return dayjs(date).isLeapYear();
};
