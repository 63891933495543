import { deepmerge } from '.';

const isBrowser = typeof window !== 'undefined';

export const getLocalStorage = (key: string): any => {
  try {
    let data = null;

    if (isBrowser) {
      data = window.localStorage?.getItem(key);
    }

    return JSON.parse(data);
  } catch (e) {
    console.warn(e);
  }

  return null;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setLocalStorage = (key: string, data: any): any => {
  try {
    if (isBrowser) {
      window.localStorage?.setItem(key, JSON.stringify(data));
    }

    return data;
  } catch (e) {
    console.warn(e);
  }

  return null;
};

export const removeLocalStorage = (key: string): boolean => {
  try {
    if (isBrowser) {
      window.localStorage?.removeItem(key);
    }

    return true;
  } catch (e) {
    console.warn(e);
  }

  return false;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateStorage = (key: string, data: any): void => {
  const currentData = getLocalStorage(key);

  if (isBrowser) {
    setLocalStorage(key, deepmerge(currentData, data));
  }
};
