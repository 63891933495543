import styled from 'styled-components';

interface LoaderIconProps {
  size: number;
  white: boolean;
}

export const LoaderIcon = styled.div<LoaderIconProps>`
  position: relative;
  margin: auto;
  width: ${props => (props.size ? props.size : 22)}px;
  height: ${props => (props.size ? props.size : 22)}px;
  font-size: 0.9rem;
  text-indent: -9999em;
  border-radius: 50%;
  border-left: 2px solid transparent;
  border-top: 2px solid ${props => (props.white ? '#ffffff' : '#1c3d5a')};
  border-right: 2px solid ${props => (props.white ? '#ffffff' : '#1c3d5a')};
  border-bottom: 2px solid ${props => (props.white ? '#ffffff' : '#1c3d5a')};
  animation: load8 1.1s infinite linear;
  transform: translateZ(0);

  &:after {
    border-radius: 50%;
    width: ${props => (props.size ? props.size : 18)}px;
    height: ${props => (props.size ? props.size : 18)}px;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

interface WrapperProps {
  fullWidth: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  display: ${props => (props.fullWidth ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: center;
  margin: 5px;
`;
